/* General styling */
body {
  font-family: Arial, sans-serif;
  scroll-behavior: smooth;
  color: #333;
  background-color: #111;
  color: #f9f9f9;
}

/* Navbar */
.navbar-custom {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.navbar-custom.sticky-top {
  background-color: #000000; /* Black background on scroll */
}

.navbar-brand {
  margin-left: 20px;
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
  color: #f8f8f8 !important;
}

/* Navbar link hover effect */
.navbar-custom .nav-link:hover {
  color: #ffe400 !important; /* Bright yellow on hover */
  transition: color 0.3s ease;
}

/* Cover Section */
.cover-section {
  height: 100vh;
  background: url("https://res.cloudinary.com/dzphailac/image/upload/v1732291681/cover_fmd78z.png")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cover-section h1 {
  font-size: 4.5rem;
  font-weight: bold;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  margin-top: -10rem;
  transition: transform 0.3s ease;
}

.cover-section h1:hover {
  transform: translateY(-10px);
}

.cover-section button {
  margin-top: 1rem;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  background-color: #ffc107;
  color: #000;
  border: none;
  transition: transform 0.3s ease;
}

.cover-section button:hover {
  transform: scale(1.05);
  background-color: #ffe400;
}

/* About Section */
.about-section {
  background: #181818;
  color: #e9e9e9;
  animation: slideUp 1s ease forwards;
  opacity: 0;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Gallery Section */
.gallery-section img {
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: grayscale(0%);
}

.gallery-section img:hover {
  transform: scale(1.05);
  filter: grayscale(0%);
}

/* Reviews Section */
.reviews-section .carousel-item {
  font-size: 1.2rem;
  padding: 1rem;
  animation: fadeIn 0.8s ease-in-out;
}

/* Contact Section */
.contact-section {
  background-color: #1c1c1c;
  color: #f3f3f3;
  padding: 4rem 0;
  animation: slideUp 1s ease forwards;
}

.contact-section h2 {
  color: #ffc107;
}

.contact-details p {
  color: #bbb;
  font-size: 1rem;
}

.contact-icons {
  display: flex;
  gap: 1.5rem;
  color: #bbb;
}

.contact-icon {
  color: #d3d3d3; /* Light gray text */
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-icon:hover {
  color: #fff;
}

/* Map Section */
.map-section {
  padding: 2rem 0;
}

.map-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Footer Section */
.footer-section {
  background-color: #000;
  color: #ffffff;
}

.footer-text {
  font-size: 0.9rem;
}

.developer-link {
  color: #ffffff;
  text-decoration: underline;
}

.developer-link:hover {
  color: #ffffff;
}

/* Smooth scroll animation for elements */
.scroll-animation {
  animation: fadeIn 1.5s ease-out;
}

@media (prefers-reduced-motion: no-preference) {
  .scroll-animation {
    animation: slideUp 1.2s ease-out;
  }
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.3);
}

.book-now-btn {
  background-color: #ffd700; /* Yellow background */
  color: #333; /* Dark text for contrast */
  border: none;
  font-weight: 600;
  padding: 0.5rem 1.2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 8px;
  margin-left: 10px;
  align-self: flex-end;
}

/* Hover and Focus Effects for Book Now Button */
.book-now-btn:hover,
.book-now-btn:focus {
  background-color: #e6b800; /* Slightly darker yellow */
  color: #fff;
  transform: translateY(-2px); /* Lifts the button slightly */
}

/* Smooth Scroll Button Animation */
.book-now-btn:active {
  transform: scale(0.95); /* Slight shrink effect on click */
}

body {
  background-color: #fff !important;
  color: #000 !important;
}

.App {
  text-align: center;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #fff; /* Override dark backgrounds */
    color: #000; /* Override dark text */
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.reviews-section {
  background-color: #f9f9f9;
  padding: 2rem 0;
}

.review-list .card {
  margin: 0.5rem 0;
}

.add-review textarea {
  width: 100%;
  resize: none;
}
.reviews-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

/* Default: 3 reviews per row on larger screens */
.reviews-list .col-md-4 {
  flex: 1 1 calc(33.333% - 16px);
  max-width: calc(33.333% - 16px);
}

/* On mobile devices (small screens), show only 1 review per row */
@media (max-width: 767px) {
  .reviews-list .col-md-4 {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.review-item {
  border-left: 5px solid #1f1f1f;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-item {
  max-height: 150px; /* Set a max height for review items */
  overflow: hidden; /* Hide content that overflows */
  position: relative;
}

.review-item.expanded {
  max-height: none; /* Remove max height when expanded */
}

.read-more-btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #000;
  color: white;
  border: none;
  font-size: 9pt;
  cursor: pointer;
  border-radius: 10px;
  opacity: 0.7;
}
